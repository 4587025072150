import React, { useLayoutEffect } from 'react'
import { ariaHideOutside } from '@react-aria/overlays'

export const useAriaHideOutside = (visibleElements: React.RefObject<HTMLElement | null>[]) => {
  useLayoutEffect(() => {
    return ariaHideOutside([
      ...visibleElements
        .map(el => el.current)
        .filter(<T>(element: T): element is NonNullable<T> => element !== null),
    ])
  }, [visibleElements])
}
