import React, { useState } from 'react'
import { animated, useTransition, UseTransitionProps } from 'react-spring'
import { Box } from '../../new/core-components/box'
import { SnackbarCustomizations } from './types'

const MIN_SNACKBAR_WIDTH = 368
const MAX_SNACKBAR_WIDTH = 624
const OFFSET_WHEN_HIDDEN = -100
const OFFSET_WHEN_SHOWING = 20
const TRANSITION_DURATION = 200
const AUTO_CLOSE_TIMEOUT = 3000

type Props = SnackbarCustomizations & {
  onClose?: () => void
}

const getTranslateStyle = (offsetInPixels: number) => ({
  transform: `translate3d(-50%,${offsetInPixels}px,0)`,
})
const from = getTranslateStyle(OFFSET_WHEN_HIDDEN)

/** @deprecated Use `useShowToast` from `@chilipiper/design-system/src/new` instead */
export const Snackbar = ({
  children,
  onClose,
  duration = AUTO_CLOSE_TIMEOUT,
  ...animatedProps
}: Props) => {
  const [isOpen, setIsOpen] = useState(true)
  const transitionOptions: UseTransitionProps = {
    from,
    enter: { ...getTranslateStyle(OFFSET_WHEN_SHOWING), onRest: () => setIsOpen(false) },
    leave: {
      ...from,
      onRest: onClose,
      delay: duration,
    },
    config: { duration: TRANSITION_DURATION },
  }

  const transitions = useTransition(isOpen, transitionOptions)

  return transitions(
    (style, item) =>
      item && (
        // @ts-expect-error - Waiting for https://github.com/pmndrs/react-spring/issues/2341
        <animated.div
          style={{
            ...style,
            position: 'fixed',
            top: '0',
            left: '50%',
            zIndex: 100,
            ...animatedProps,
          }}
          data-test-id='Snackbar'
        >
          <Box
            width='fit-content'
            my={0}
            mx='auto'
            maxWidth={MAX_SNACKBAR_WIDTH}
            minWidth={MIN_SNACKBAR_WIDTH}
            aria-live='polite'
          >
            {children}
          </Box>
        </animated.div>
      )
  )
}
