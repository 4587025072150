import React, { ComponentType, createContext, Provider, use } from 'react'

type ContextName<BaseName extends string> = `${Lowercase<BaseName>}Context`
type HookName<BaseName extends Capitalize<string>> = `use${BaseName}Context`
type BaseProviderName<BaseName extends Capitalize<string>> = `${BaseName}BaseProvider`
type HOCName<BaseName extends Capitalize<string>> = `with${BaseName}Context`
type NoContextProviderState = Record<never, never>

const NO_CONTEXT_PROVIDER_STATE: NoContextProviderState = Symbol('NO_CONTEXT_PROVIDER_STATE')
const isNoContextProviderState = (arg: unknown): arg is NoContextProviderState =>
  arg === NO_CONTEXT_PROVIDER_STATE

export const createContextWithHook =
  <ContextState,>() =>
  // Nested functions are the only way I could find to have TS infer the BaseName even with explicit ContextState
  // to avoid having to pass the BaseName both as type and as string
  // There are proposals in TS to allow type inference + explicit types, which would allow this to be simplified: https://github.com/microsoft/TypeScript/pull/26349
  <BaseName extends Capitalize<string>>(baseName: BaseName) => {
    const reactContext = createContext<ContextState | NoContextProviderState>(
      NO_CONTEXT_PROVIDER_STATE
    )
    const contextName: ContextName<BaseName> = `${
      baseName.toLowerCase() as Lowercase<BaseName>
    }Context` as const
    const hookName: HookName<BaseName> = `use${baseName}Context` as const
    const baseProviderName: BaseProviderName<BaseName> = `${baseName}BaseProvider` as const
    const hocName: HOCName<BaseName> = `with${baseName}Context` as const

    reactContext.displayName = `${baseName}Context`
    const useContextWithErrorHandling = () => {
      const state = use(reactContext)
      if (isNoContextProviderState(state)) {
        throw new Error(`${hookName} must be used within a ${baseName}Provider`)
      }
      return state
    }

    const withContext = <Props extends { [K in ContextName<BaseName>]: ContextState }>(
      Component: ComponentType<Props>
    ) => {
      const ComponentWithContext = (props: Omit<Props, ContextName<BaseName>>) => {
        const context = useContextWithErrorHandling()
        const propsWithContext = { ...props, [contextName]: context } as Props & {
          [K in ContextName<BaseName>]: ContextState
        }
        return <Component {...propsWithContext} />
      }
      ComponentWithContext.displayName = Component.displayName
      return ComponentWithContext
    }

    return {
      [hocName]: withContext,
      [hookName]: useContextWithErrorHandling,
      [baseProviderName]: reactContext.Provider,
    } as {
      [K in HOCName<BaseName>]: typeof withContext
    } & {
      [K in HookName<BaseName>]: typeof useContextWithErrorHandling
    } & {
      [K in BaseProviderName<BaseName>]: Provider<ContextState>
    }
  }
