import styled, { css } from 'styled-components'
import CSS from 'csstype'
import {
  compose,
  flexGrow,
  FlexGrowProps,
  flexShrink,
  FlexShrinkProps,
  FontSizeProps,
  FontStyleProps,
  FontWeightProps,
  layout,
  LayoutProps,
  LetterSpacingProps,
  LineHeightProps,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
  TextAlignProps,
  TLengthStyledSystem,
  typography,
} from 'styled-system'
import { Theme, ThemeColors } from '../../theme'
import {
  ThemeFontFamily,
  ThemeFontSizes,
  ThemeFontWeights,
  ThemeLineHeights,
  ThemeOpacities,
} from '../../theme/types'
import { getTextStyleCss, TextStyle } from './helpers'

type TextProps = LayoutProps<Theme> &
  SpaceProps<Theme, CSS.Property.Margin<TLengthStyledSystem>> &
  FlexGrowProps<Theme> &
  FlexShrinkProps<Theme> &
  FontSizeProps<Theme, ThemeFontSizes | CSS.Property.FontSize<TLengthStyledSystem>> &
  FontWeightProps<Theme, ThemeFontWeights | CSS.Property.FontWeight> &
  LetterSpacingProps<Theme, CSS.Property.LetterSpacing<TLengthStyledSystem>> &
  LineHeightProps<Theme, ThemeLineHeights | CSS.Property.LineHeight<TLengthStyledSystem>> &
  FontStyleProps<Theme> &
  TextAlignProps<Theme> & {
    color?: ResponsiveValue<ThemeColors, Theme>
    fontFamily?: ResponsiveValue<ThemeFontFamily | CSS.Property.FontFamily, Theme>
    opacity?: ResponsiveValue<ThemeOpacities | CSS.Property.Opacity, Theme>
    overflowWrap?: ResponsiveValue<CSS.Property.OverflowWrap, Theme>
    textDecoration?: ResponsiveValue<CSS.Property.TextDecoration, Theme>
    textDecorationColor?: ResponsiveValue<ThemeColors, Theme>
    textOverflow?: ResponsiveValue<CSS.Property.TextOverflow, Theme>
    textTransform?: ResponsiveValue<CSS.Property.TextTransform, Theme>
    whiteSpace?: ResponsiveValue<CSS.Property.WhiteSpace, Theme>
    wordBreak?: ResponsiveValue<CSS.Property.WordBreak, Theme>
  }

const textCss = css<TextProps>`
  ${compose(typography, space, layout, flexGrow, flexShrink)}
  ${system({
    opacity: {
      property: 'opacity',
      scale: 'opacities',
    },
    overflowWrap: {
      property: 'overflowWrap',
    },
    textTransform: {
      property: 'textTransform',
    },
    textDecoration: {
      property: 'textDecoration',
    },
    textDecorationColor: {
      property: 'textDecorationColor',
      scale: 'colors',
    },
    textOverflow: {
      property: 'textOverflow',
    },
    whiteSpace: {
      property: 'whiteSpace',
    },
    wordBreak: {
      property: 'wordBreak',
    },
    bg: {
      property: 'background', // the default was 'backgroundColor' but we need to use gradients too
      scale: 'colors',
    },
    color: {
      property: 'color',
      scale: 'colors',
    },
  })}
`

export const Text = styled.span<TextProps & { textStyle?: TextStyle }>`
  ${({ theme }) => css`
    font-family: ${theme.fonts.primary};
    line-height: ${theme.lineHeights['none']};
    font-size: ${theme.fontSizes[14]};
    color: ${theme.colors['text/body-major']};
  `}
  ${({ textStyle }) => textStyle && getTextStyleCss(textStyle)}
  ${textCss}
`

Text.displayName = 'Text'

/** @deprecated Use Text instead */
export const LabelText = styled(Text).attrs(props => ({
  color: 'text/body-moderate',
  fontWeight: 600,
  mb: 2,
  display: 'block',
  as: 'label',
  ...props,
}))<{ htmlFor?: string }>``

export const TextEllipsis = styled(Text).attrs(props => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  display: 'block',
  width: '100%',
  ...props,
}))``
