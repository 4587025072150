/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const IconInformation = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M8 14.125a6.125 6.125 0 1 1 0-12.25 6.125 6.125 0 0 1 0 12.25M.125 8a7.875 7.875 0 1 0 15.75 0A7.875 7.875 0 0 0 .125 8M8 11.675a.875.875 0 0 0 .875-.875V8a.875.875 0 1 0-1.75 0v2.8c0 .483.392.875.875.875M7.125 5.2c0 .483.392.875.875.875h.007a.875.875 0 1 0 0-1.75H8a.875.875 0 0 0-.875.875'
      clipRule='evenodd'
    />
  </svg>
)
