import { useFocusRing } from 'react-aria'
import React, { useLayoutEffect, useState } from 'react'

export const useFocusVisible = (forceFocus?: boolean) => {
  // `react-aria` uses element.focus() in `useButton` breaking `:focus-visible` on macOS on all browsers - see https://bugzilla.mozilla.org/show_bug.cgi?id=1618639#c10
  const { focusProps, isFocusVisible: isRealFocusVisible } = useFocusRing()
  const isFocusVisible = forceFocus || isRealFocusVisible
  return { focusProps, isFocusVisible }
}
export const useElementSize = (elementRef: React.RefObject<HTMLElement | null>) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const observer = new ResizeObserver(([trigger]) => {
      setWidth(trigger.target.getBoundingClientRect().width)
      setHeight(trigger.target.getBoundingClientRect().height)
    })
    if (elementRef.current) {
      observer.observe(elementRef.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [elementRef])
  return { width, height }
}
