import * as R from 'remeda'
import { remToPx } from './remToPx'
import { dimensions } from './tokens'

const baseSpace = {
  0: '0rem', // 0px
  // xs
  1: '0.25rem', // 4px
  2: '0.5rem', // 8px
  3: '0.75rem', // 12px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px

  // sm
  7: '2rem', // 32px
  8: '2.5rem', // 40px
  9: '3rem', // 48px
  10: '3.5rem', // 56px
  11: '4rem', // 64px

  // md
  12: '5rem', // 80px
  13: '6rem', // 96px
  14: '7rem', // 112px
  15: '8rem', // 128px
  16: '9rem', // 144px

  // lg
  17: '11rem', // 176px
  18: '13rem', // 208px
  19: '15rem', // 240px
  20: '17rem', // 272px
  21: '19rem', // 304px

  // xl
  22: '23rem', // 368px
  23: '27rem', // 432px
  24: '31rem', // 496px
  25: '35rem', // 560px
  26: '39rem', // 624px

  // 2xl
  27: '47rem', // 752px
  28: '55rem', // 880px
  29: '63rem', // 1008px
  30: '71rem', // 1136px
  31: '79rem', // 1264px

  // 3xl
  32: '95rem', // 1520px
  33: '111rem', // 1776px
  34: '127rem', // 2032px
  35: '143rem', // 2288px
  36: '159rem', // 2544px
}

const sizes = {
  ...baseSpace,
  ...dimensions.Height,
}

const space = {
  ...baseSpace,
  ...dimensions.Space,
}

export const getSpacing = (unit?: 'px'): { sizes: typeof sizes; space: typeof space } => {
  if (unit === 'px') {
    return {
      sizes: R.mapValues(sizes, remToPx),
      space: R.mapValues(space, remToPx),
    }
  }
  return {
    sizes,
    space,
  }
}
