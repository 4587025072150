import type { ComponentProps, JSXElementConstructor, ReactElement, ReactNode } from 'react'

export const typeValidator =
  <T extends Array<JSXElementConstructor<any>>>(...Components: T) =>
  (element: ReactNode): element is ReactElement<ComponentProps<T[number]>, T[number]> =>
    !!(
      element &&
      typeof element !== 'boolean' &&
      typeof element !== 'number' &&
      typeof element !== 'string' &&
      typeof element !== 'bigint' &&
      'type' in element &&
      typeof element.type !== 'string' &&
      Components.includes(element.type)
    )
