import { css } from 'styled-components'
import * as R from 'remeda'
import { ThemeLineHeights } from '../../theme/types'
import { textPropsByTextStyle } from '../../theme/textStyles'
import { typography } from '../../theme/tokens'

const generatedTextStyles = { ...typography.Base, ...typography.Component }

type GeneratedTextStyle = keyof typeof generatedTextStyles

export type TextStyle = keyof typeof textPropsByTextStyle | GeneratedTextStyle

export const getTextStyleCss = (textStyle: TextStyle) => {
  if (textStyle in generatedTextStyles) {
    const properties = generatedTextStyles[textStyle as GeneratedTextStyle]
    return css`
      ${R.entries(properties).map(([key, value]) => {
        return `${R.toKebabCase(key)}: ${value};`
      })}
    `
  }
  const { fontWeight, fontSize, fontFamily, lineHeight, ...rest } = textPropsByTextStyle[textStyle]
  return css`
    font-family: ${({ theme }) => (fontFamily ? theme.fonts[fontFamily] : undefined)};
    font-size: ${({ theme }) => (fontSize ? theme.fontSizes[fontSize] : undefined)};
    font-weight: ${({ theme }) => (fontWeight ? theme.fontWeights[fontWeight] : undefined)};
    line-height: ${({ theme }) =>
      lineHeight ? (theme.lineHeights[lineHeight as ThemeLineHeights] ?? lineHeight) : undefined};
    text-transform: ${'textTransform' in rest ? rest.textTransform : undefined};
  `
}
