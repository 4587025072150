/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const IconSmartFeature = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16' {...props}>
    <g clipPath='url(#clip0_4782_15652)'>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M8 .25a.75.75 0 0 1 .7.48l1.408 3.663c.212.549.275.7.361.82q.133.185.318.318c.12.086.271.15.82.36L15.27 7.3a.75.75 0 0 1 0 1.4l-3.661 1.408c-.55.212-.7.275-.82.361q-.187.133-.319.318c-.086.12-.15.271-.36.82L8.7 15.27a.75.75 0 0 1-1.4 0l-1.408-3.661c-.212-.55-.275-.7-.361-.82a1.4 1.4 0 0 0-.318-.319c-.12-.086-.271-.15-.82-.36L.73 8.7a.75.75 0 0 1 0-1.4l3.662-1.408c.549-.212.7-.275.82-.361a1.4 1.4 0 0 0 .318-.318c.086-.12.15-.271.36-.82L7.3.73A.75.75 0 0 1 8 .25m0 2.84L7.292 4.93l-.029.074c-.17.441-.3.783-.51 1.077a2.9 2.9 0 0 1-.67.671c-.295.21-.637.34-1.078.51l-.074.029L3.089 8l1.842.708.074.029c.441.17.783.3 1.077.51.26.184.487.411.671.67.21.296.34.637.51 1.078l.029.074L8 12.911l.708-1.842.029-.074c.17-.441.3-.782.51-1.077a2.9 2.9 0 0 1 .67-.671c.296-.21.637-.34 1.078-.51l.074-.029L12.911 8l-1.842-.708-.074-.029c-.441-.17-.782-.3-1.077-.51a2.9 2.9 0 0 1-.671-.67c-.21-.295-.34-.637-.51-1.078l-.029-.074z'
        clipRule='evenodd'
      />
    </g>
    <defs>
      <clipPath id='clip0_4782_15652'>
        <path fill='currentColor' d='M0 0h16v16H0z' />
      </clipPath>
    </defs>
  </svg>
)
